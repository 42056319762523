import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot, faTruckFast, faExplosion } from "@fortawesome/free-solid-svg-icons"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from "react-i18next"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import Title from "../shared/Title"
import "./SwCarousel.css"

export default function () {
  const { t } = useTranslation()
  return (
    <>
      <div className="container">
        <Title id="servicios" title={t("navbar.services")} />
        <Swiper
          pagination={{
            clickable: true
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15
            }
          }}
        >
          <SwiperSlide>
            <div className="slide-content">
              <div className="image-content">
                <div className="card-image">
                  <FontAwesomeIcon icon={faTruckFast} className="card-img" />
                </div>
                <h2 className="name">{t("services.card_1.title")}</h2>
              </div>
              <div className="card-content">
                <p className="description">{t("services.card_1.description_1")}</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-content">
              <div className="card-image">
                <FontAwesomeIcon icon={faLocationDot} className="card-img" />
              </div>
              <h2 className="name">{t("services.card_2.title")} </h2>
            </div>
            <div className="card-content">
              <p className="description">{t("services.card_2.description_1")}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-content">
              <div className="card-image">
                <FontAwesomeIcon icon={faLocationDot} className="card-img" />
              </div>
              <h2 className="name">{t("services.card_3.title")}</h2>
            </div>
            <div className="card-content">
              <p className="description">{t("services.card_3.description_1")}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image-content">
              <div className="card-image">
                <FontAwesomeIcon icon={faExplosion} className="card-img" />
              </div>
              <h2 className="name">{t("services.card_4.title")}</h2>
            </div>
            <div className="card-content">
              <p className="description">{t("services.card_4.description_1")}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}
