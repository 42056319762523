import { ChangeEvent, FormEvent, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import {
  SectionContact,
  Wrapper,
  WrapperForm,
  InputBox,
  InputSelect,
  InputBoxArea,
  StyledReCaptcha,
  ContactProps
} from "./contactStyled"
import axios from "../../utils/axios"
import Swal from "sweetalert2"

const countries = [
  { label: "colombia", value: "Colombia" },
  { label: "mexico", value: "México" },
  { label: "peru", value: "Perú" },
  { label: "united-states", value: "United States" },
  { label: "ecuador", value: "Ecuador" },
  { label: "panam", value: "Panamá" },
  { label: "argentina", value: "Argentina" },
  { label: "chile", value: "Chile" },
  { label: "costarica", value: "Costa Rica" },
  { label: "uruguay", value: "Uruguay" },
  { label: "bolivia", value: "Bolivia" },
  { label: "puertorico", value: "Puerto Rico" },
  { label: "venezuela", value: "Venezuela" },
  { label: "guatem", value: "Guatemala" },
  { label: "repdomin", value: "República Dominicana" }
]
const RECAPTCHA_SITE_KEY = "6Lc2_XYlAAAAAHC5JCNOWVdwNAbNg7zxSmyqpdoR"

export default function (props: ContactProps) {
  const { t } = useTranslation()

  const reCaptchaRef = useRef<ReCAPTCHA>(null)
  const [captchaValid, setCaptchaValid] = useState<boolean | null>(null)
  const [formContact, setFormContact] = useState({
    reCaptcha: "",
    name: "",
    phone: "",
    email: "",
    subject: "",
    country: "",
    message: ""
  })

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setFormContact({
      ...formContact,
      [name]: value
    })
  }
  const captchaChange = () => {
    const recaptchaValue = reCaptchaRef.current?.getValue() || ""
    setFormContact({ ...formContact, reCaptcha: recaptchaValue })
    if (recaptchaValue) {
      console.log("el usuario no es un robot")
      setCaptchaValid(true)
    }
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (reCaptchaRef.current?.getValue()) {
      try {
        await axios.post("/contactus", formContact)
        setFormContact({
          reCaptcha: "",
          name: "",
          phone: "",
          email: "",
          subject: "",
          country: "",
          message: ""
        })
        await Swal.fire({
          icon: "success",
          title: "¡Enviado!",
          showConfirmButton: false,
          timer: 2000
        })
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: "¡Server error!",
          showConfirmButton: true,
          timer: 1000
        })
        console.error("Error al enviar correo electrónico: " + error)
      }
    } else {
      console.log("por favor acepta el captcha")
      setCaptchaValid(false)
    }
  }

  return (
    <SectionContact vertical={props.vertical}>
      <Wrapper vertical={props.vertical}>
        {props.vertical ? <h3 className="title-contact">{t("navbar.contact")}</h3> : null}
        <form onSubmit={handleSubmit}>
          <WrapperForm vertical={props.vertical}>
            <InputBox vertical={props.vertical}>
              <label htmlFor="name">{t("contact.name")}</label>
              <input
                name="name"
                type="text"
                placeholder={t("contact.ph_name") ?? ""}
                required
                maxLength={50}
                onChange={handleInputChange}
              />
            </InputBox>
            <InputBox vertical={props.vertical}>
              <label htmlFor="name">{t("contact.phone")}</label>
              <input
                name="phone"
                type="text"
                required
                placeholder={t("contact.ph_phone") ?? ""}
                value={formContact.phone}
                onChange={handleInputChange}
                maxLength={17}
                pattern="^[+\-\d\s]+$"
              />
            </InputBox>
            <InputBox vertical={props.vertical}>
              <label htmlFor="email">{t("contact.email")}</label>
              <input
                name="email"
                type="email"
                required
                placeholder={t("contact.ph_email") ?? ""}
                value={formContact.email}
                onChange={handleInputChange}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              />
            </InputBox>
            <InputBox vertical={props.vertical}>
              <label htmlFor="subject">{t("contact.subject")}</label>
              <input
                name="subject"
                placeholder={t("contact.ph_subject") ?? ""}
                type="text"
                required
                value={formContact.subject}
                onChange={handleInputChange}
              />
            </InputBox>
            <InputSelect vertical={props.vertical}>
              <label htmlFor="country">{t("contact.country")}</label>
              <select
                name="country"
                required
                value={formContact.country}
                onChange={handleInputChange}
              >
                <option value="">{t("contact.ph_country") ?? ""}</option>
                {countries.map((country) => (
                  <option key={country.label} value={country.value}>
                    {country.value}
                  </option>
                ))}
              </select>
            </InputSelect>
            <InputBoxArea vertical={props.vertical}>
              <label htmlFor="subject">{t("contact.message")}</label>
              <textarea
                name="message"
                maxLength={800}
                rows={5}
                required
                placeholder={t("contact.ph_message") ?? ""}
                value={formContact.message}
                onChange={handleInputChange}
              />
            </InputBoxArea>

            <StyledReCaptcha
              ref={reCaptchaRef}
              style={{
                borderWidth: 1,
                borderColor: "red"
              }}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={captchaChange}
              autoCorrect={"required"}
            />
            {captchaValid === false && (
              <div style={{ color: "red" }}>Porfavor acepta el captcha</div>
            )}
            <div className="buton-conteiner">
              <button>{t("send")}</button>
            </div>
          </WrapperForm>
        </form>
      </Wrapper>
    </SectionContact>
  )
}
