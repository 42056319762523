import Title from "../shared/Title"
import CardIndex from "./CardIndex"
import Contact from "../contact/Contact"
import Footer from "../footer/Footer"
import NavBar from "../header/NavBar"
import video from "../../assets/img/video.mp4"
import { useTranslation } from "react-i18next"
import fotoGpsMesh from "../../assets/img/GpsMesh.webp"

export default function () {
  const { t } = useTranslation()
  return (
    <>
      <NavBar />
      <img
        style={{ width: "100%", maxHeight: "600px", height: "auto" }}
        src={fotoGpsMesh}
        alt="GpsMesh"
      ></img>
      <Title title={t("services.card_3.title")} />
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-md-6 col-xm-12">
            <CardIndex
              video={video}
              description={t("services.card_3.description_3")}
              caracteristicas={t("services.card_3.characteristics")}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-xm-12">
            <Contact vertical={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
