import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./Drawer.css"
import Profile from "../../assets/img/Logo.webp"
import IndexNews from "./IndexNews"
import ListUser from "../login/ListUser"

import {
  FaBars,
  FaChevronRight,
  FaCog,
  FaNewspaper,
  FaSearch,
  FaSignOutAlt,
  FaUser
} from "react-icons/fa"

export default function Drawer() {
  const navigate = useNavigate()

  function handleLogout() {
    sessionStorage.removeItem("token")
    navigate("/login") // Reemplaza "/login" con la ruta correspondiente a la página de inicio de sesión
  }

  const [showNews, setShowNews] = useState(true)
  const [showUser, setShowUser] = useState(false)

  const allSideMenuRef = useRef<HTMLAnchorElement[]>([])
  const [sidebarVisible, setSidebarVisible] = useState(true)

  const handleMenuClick = () => {
    setSidebarVisible(!sidebarVisible)
  }

  useEffect(() => {
    const handleClick = (event: Event) => {
      allSideMenuRef.current.forEach((item) => {
        item.parentElement?.classList.remove("active")
      })
      const li = (event.currentTarget as HTMLAnchorElement)?.parentElement
      li?.classList.add("active")
      if (event.currentTarget === allSideMenuRef.current[0]) {
        setShowNews(true)
        setShowUser(false)
      } else if (event.currentTarget === allSideMenuRef.current[1]) {
        setShowNews(false)
        setShowUser(true)
      }
    }
    allSideMenuRef.current.forEach((item) => {
      item.addEventListener("click", handleClick)
    })
  }, [])

  return (
    <div className="admin-drawer">
      <section id="sidebar" className={sidebarVisible ? "" : "hide"}>
        <a href="#" className="brand" onClick={handleMenuClick}>
          <img src={Profile} alt="" className="bx" />
          <span>Admin DTSmine</span>
        </a>
        <ul className="side-menu top">
          <li className="active">
            <a href="#" ref={(el) => el && allSideMenuRef.current.push(el)}>
              <FaNewspaper className="bx" />
              <span className="text">Noticias</span>
            </a>
          </li>
          <li>
            <a href="#" ref={(el) => el && allSideMenuRef.current.push(el)}>
              <FaUser className="bx" />
              <span className="text">Usuarios</span>
            </a>
          </li>
          <li>
            <a href="#" ref={(el) => el && allSideMenuRef.current.push(el)}>
              <FaCog className="bx" />
              <span className="text">Setting</span>
            </a>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <a href="#" className="logout" onClick={handleLogout}>
              <FaSignOutAlt className="bx" />
              <span className="text">Logout</span>
            </a>
          </li>
        </ul>
      </section>
      <section id="content">
        <nav>
          <FaBars className="bx bx-menu" onClick={handleMenuClick} />
          <a href="#" className="nav-link">
            Categories
          </a>
          <form action="#">
            <div className="form-input">
              <input type="search" placeholder="search..." />
              <button type="submit" className="search-btn">
                <FaSearch className="bx bx-search" />
              </button>
            </div>
          </form>
          <input type="checkbox" id="switch-mode" hidden />
          {/* <a href="#" className="profile">
            <span>Jaime Landa</span>
            <img src={Profile} alt="" />
          </a>*/}
        </nav>
        <main>
          <div className="head-title">
            <ul className="breadcrumb">
              <li>
                <a href="#">Noticias</a>
              </li>
              <li>
                <FaChevronRight />
              </li>
              <li>
                <a className="active" href="#">
                  lista
                </a>
              </li>
            </ul>
          </div>
          {showNews && <IndexNews />}
          {showUser && <ListUser />}
        </main>
      </section>
    </div>
  )
}
