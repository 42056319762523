import { Line } from "../services/ServicesStyle"

type TitleProps = {
  title: string
  id?: string
}

export default function ({ title, id }: TitleProps) {
  return (
    <div
      style={{
        margin: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative"
      }}
    >
      <div id={id} style={{ position: "absolute", top: -100 }}></div>
      <h1
        style={{
          fontWeight: "bold",
          color: "#0044AA",
          fontSize: "1.5rem"
        }}
      >
        {title}
      </h1>
      <Line />
    </div>
  )
}
