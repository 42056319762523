import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

export default function () {
  const { lng } = useParams()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!lng || !i18n.languages.includes(lng)) return navigate(`/${i18n.language}${pathname}`)
    i18n.changeLanguage(lng)
  }, [lng])

  useEffect(() => {
    if (lng && lng !== i18n.language) {
      const parts = pathname.split(lng)
      if (parts.length === 2) {
        navigate(`/${i18n.language}${parts[1]}`)
      }
    }
  }, [lng, i18n.language])

  return <Outlet />
}
