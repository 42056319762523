import "./FluidCard.css"
import { useTranslation } from "react-i18next"

type CardIndexProps = {
  video: string
  description: string
  caracteristicas: string
}

export default function ({ video, description, caracteristicas }: CardIndexProps) {
  const { t } = useTranslation()
  return (
    <div>
      <div className="text-with-video">
        <video className="p-0 m-0" src={video} controls>
          {video}
        </video>
        <p>{description}</p>
      </div>
      <div>
        <h3 className="text-center " style={{ color: "#0069aa" }}>
          {t("characteristics")}
        </h3>
        <p>{caracteristicas}</p>
      </div>
    </div>
  )
}
