import { Noticia } from "./IndexNews"
import { StatusCode } from "status-code-enum"
import axios from "../../utils/axios"
import { ChangeEvent, DragEvent, FormEvent, useEffect, useState } from "react"
import { Crop, PixelCrop } from "react-image-crop"
import Swal from "sweetalert2"

const initialForm: Noticia = {
  _id: "",
  title: "",
  summary: "",
  description: "",
  published: true,
  img: []
}

interface Image {
  file: Blob
  url: string
}
interface Props {
  id: string | null
  changeStatus: (value: boolean) => void
}
export const useNews = ({ id, changeStatus }: Props) => {
  const [form, setForm] = useState(initialForm)
  const [stringImg, setStringImg] = useState<string>("")
  const [images, setImages] = useState<Image[]>([])
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>()
  const [cropImgFotocheck, setCropImgFotochek] = useState<Crop | undefined>({
    unit: "%", // Can be 'px' or '%'
    x: 20,
    y: 20,
    width: 100,
    height: 50
  })
  const [visibleCrop, setVisibleCrop] = useState<boolean>(true)

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
  }

  const handleDescription = (desc: string) => {
    setForm({ ...form, description: desc })
  }

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.checked
    })
  }

  useEffect(() => {
    if (id) {
      axios.get<Noticia>(`/news/${id}`).then(({ data }) => {
        setForm(data)
      })
    }
  }, [id])

  useEffect(() => {
    if (form._id) {
      axios.get(`/news/${form._id}`).then(({ data }) => {
        setImages(
          data.img.map((i: string) => ({
            url: i
          }))
        )
      })
    }
  }, [form._id])

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    if (!form.title) {
      alert("Ingresar título")
      return
    }
    if (!form._id) {
      await createData(form)
    } else {
      await updateData(form)
    }
    setForm(initialForm)
    // setDataToEdit(null)
  }

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => e.currentTarget.classList.add("dragover")
  const onDragLeave = (e: DragEvent<HTMLDivElement>) => e.currentTarget.classList.remove("dragover")

  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  function onFileDrop(e: ChangeEvent<HTMLInputElement>) {
    setVisibleCrop(true)
    setCropImgFotochek(undefined)
    const file = e.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const img = new Image()
      img.onload = () => {
        if (img.width < 1800 || img.height < 1000) {
          alert("La imagen debe tener un tamaño mínimo de 1800x1000 píxeles")
          return
        }
        // setImages([...images, { file, url: reader.result as string }])
        setStringImg(reader.result as string)
      }
      img.src = reader.result as string
    }
  }

  const cropImageNow = (image: HTMLImageElement | null) => {
    if (!image) return
    setVisibleCrop(false)
    convertToImage2(image, completedCrop as PixelCrop)
  }

  function convertToImage2(image: HTMLImageElement, crop: PixelCrop) {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    if (!ctx) return

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const pixelRatio = window.devicePixelRatio

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = "high"

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    )

    ctx.restore()

    canvas.toBlob((blob) => {
      if (!blob) return
      const imageObject = {
        file: blob,
        url: canvas.toDataURL("image/jpeg")
      }
      setImages([...images, imageObject])
    })
  }

  const removeImage = (index: number) => {
    const updatedFiles = [...images]
    updatedFiles.splice(index, 1)
    setImages(updatedFiles)
    //axios.post(`/${id}/deleteSource`, { path: "" })
  }

  async function createData(data: Noticia) {
    const formData = new FormData()
    formData.append("title", data.title)
    formData.append("summary", data.summary)
    formData.append("description", data.description)
    formData.append("published", data.published.toString())
    for (const { file } of images) {
      formData.append("images", file)
    }
    try {
      const res = await axios.post("/news", formData, {
        headers: {
          "x-access-token": sessionStorage.getItem("token")
        }
      })
      if (res.status === StatusCode.SuccessCreated) {
        changeStatus(false)
        await Swal.fire({
          icon: "success",
          title: "¡Enviado!",
          showConfirmButton: false,
          timer: 2000
        })
        /*if (res) navigate("/noticias/admin", { replace: true })*/
        console.log("se agregó la noticia")
      }
    } catch (error) {
      console.log("no se guardó la noticia", error)
      await Swal.fire({
        icon: "error",
        title: "¡Server error!",
        showConfirmButton: true,
        timer: 1000
      })
    }
  }

  async function updateData(data: Noticia) {
    const formData = new FormData()
    formData.append("title", data.title)
    formData.append("summary", data.summary)
    formData.append("description", data.description)
    formData.append("published", data.published + "")

    for (const { file, url } of images) {
      if (file) {
        formData.append("images", file)
      } else {
        formData.append("urls", url)
      }
    }

    try {
      const res = await axios.put(`/news/${data._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": sessionStorage.getItem("token")
        }
      })
      if (res.status === StatusCode.SuccessOK) {
        changeStatus(false)
        /*   if (res) navigate("/noticias/listar", { replace: true })*/
        console.log("se agregó la noticia")
      }
    } catch (error) {
      console.log("no se actualizó la noticia", error)
    }
  }

  return {
    images,
    stringImg,
    setCompletedCrop,
    setCropImgFotochek,
    cropImgFotocheck,
    cropImageNow,
    visibleCrop,
    form,
    titleForm: id ? "Editar Noticia" : "Noticias",
    handleChange,
    handleChecked,
    handleDescription,
    onDragEnter,
    onDragLeave,
    onFileDrop,
    handleRemoveImage: removeImage,
    handleSubmit
  }
}
