import Header from "../header/NavBar"
import Footer from "../footer/Footer"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import "./AboutUs.css"
import Title from "../shared/Title"
import about_analytcs from "../../assets/img/about_analytcs.svg"

export default function () {
  const { t } = useTranslation()
  const [myRef, myElementIsVisible] = useInView({ root: null, rootMargin: "100px", threshold: 0.7 })

  return (
    <>
      <Header />
      <section className="card border-0">
        <div className="content__img--cover" />
        <div className="container card-body card-img-overlay ">
          <div className="row align-items-center text-white ">
            <div className="col-md-12 col-lg-5 col-xl-5">
              <h1 className="card-title text-center">DTSmine</h1>
              <p className=" display-6 text-center card-text">{t("about.title")}</p>
            </div>
          </div>
        </div>
      </section>
      <Title title={t("navbar.about")} id="#contactus" />
      <section className="about container">
        <article className="about__article">
          <p className="about__article__text">{t("about.description")}</p>
        </article>
        <img
          ref={myRef}
          className={`about__image ${myElementIsVisible ? "slide-in" : ""}`}
          src={about_analytcs}
          alt="image"
        />
      </section>

      <Footer />
    </>
  )
}
