import { useTranslation } from "react-i18next"
import { ChangeEvent } from "react"

export default function LanguageSelector() {
  const { i18n } = useTranslation()

  const onClickLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectLng = e.target.value
    i18n.changeLanguage(selectLng)
  }

  return (
    <div>
      <select
        value={i18n.language}
        name="custom-select"
        style={{ width: 80, backgroundColor: "black", color: "white", border: "none", margin: 5 }}
        onChange={onClickLanguageChange}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
    </div>
  )
}
