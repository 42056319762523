import ReCaptcha from "react-google-recaptcha"
import styled from "styled-components"

export type ContactProps = {
  vertical: boolean
}

export const SectionContact = styled.div<ContactProps>`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-image: linear-gradient(#f5f3f3, #b5b3b3);
  .title-contact {
    color: #0069aa;
    border-bottom: 2px solid #fff;
  }
`

export const Wrapper = styled.div<ContactProps>`
  max-width: ${(props) => (props.vertical ? "100%" : "70%")};
  padding: 28px;
  margin: 0 28px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`
export const WrapperForm = styled.div<ContactProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  padding: ${(props) => (props.vertical ? "0" : "20px 0")};
  @media (max-width: 580px) {
    max-height: ${(props) => (props.vertical ? "100vh" : "500px")};
    overflow: auto;
    width: 100%;
    padding: 0;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .buton-conteiner {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .buton-conteiner button {
    width: 100%;
    border: none;
    color: #f0f0f0;
    border-radius: 5px;
    height: 35px;
    background-color: #0069aa;
  }
`

export const InputBox = styled.div<ContactProps>`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.vertical ? "105%" : "50%")};
  padding-bottom: ${(props) => (props.vertical ? "0" : "15px")};

  &:nth-child(even) {
    justify-content: ${(props) => (props.vertical ? "space-between" : "end")};
  }

  label {
    color: #0069aa;
    font-weight: bold;
    margin: 5px 0;
    width: 95%;
  }

  input {
    height: ${(props) => (props.vertical ? "35px" : "40px")};
    flex-wrap: wrap;
    width: 95%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }

  input:is(:focus, :valid) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 580px) {
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    input {
      height: 35px;
      padding-bottom: 0;
      width: 100%;
    }

    &:nth-child(even) {
      justify-content: space-between;
    }
  }
`
export const InputSelect = styled.div<ContactProps>`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.vertical ? "105%" : "50%")};
  padding-bottom: ${(props) => (props.vertical ? "0" : "15px")};

  &:nth-child(even) {
    justify-content: ${(props) => (props.vertical ? "space-between" : "end")};
  }

  label {
    color: #0069aa;
    font-weight: bold;
    margin: 5px 0;
    width: 95%;
  }

  select {
    height: ${(props) => (props.vertical ? "35px" : "40px")};
    flex-wrap: wrap;
    width: 95%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }

  select:is(:focus, :valid) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 580px) {
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    select {
      height: 35px;
      padding-bottom: 0;
      width: 100%;
    }

    &:nth-child(even) {
      justify-content: space-between;
    }
  }
`
export const InputBoxArea = styled.div<ContactProps>`
  width: ${(props) => (props.vertical ? "100%" : "105%")};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  label {
    color: #0069aa;
    font-weight: bold;
  }
  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 16px;
    color: #020202;
    resize: none;
    line-height: 1.6;
    padding: 10px;
  }
  textarea:is(:focus, :valid) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }
  @media (max-width: 580px) {
    width: 100%;
    
`

export const StyledReCaptcha = styled(ReCaptcha)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 78px !important;
  .error_captcha {
    color: red;
  }
`
