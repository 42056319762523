import { FaEdit, FaPlus, FaSearch, FaTrash } from "react-icons/fa"
import "./ListNews.css"
import { Noticia } from "./IndexNews"

interface Props {
  dataList: Noticia[]
  deleteData: (_: string) => void
  changeStatus: (value: boolean) => void
  setEditItemId: (id: string) => void
}

export default function ListNews({ dataList, deleteData, changeStatus, setEditItemId }: Props) {
  const handleEditClick = (itemId: string) => {
    setEditItemId(itemId)
    changeStatus(true)
  }

  return (
    <div className="content_list_news">
      <div className="list_news">
        <div className="head">
          <h3>Noticias</h3>
          <FaSearch className="bx" />
          <a
            className="btn btn-success btn-sm"
            onClick={() => {
              setEditItemId("")
              changeStatus(true)
            }}
          >
            <FaPlus /> Nueva
          </a>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th scope="col">Título</th>
              <th scope="col">Resumen</th>
              <th scope="col">Publicado</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.title}</td>
                <td>{item.summary}</td>
                <td>{<input type="checkbox" defaultChecked={item.published} />}</td>

                <td>
                  <a
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => handleEditClick(item._id)}
                  >
                    <FaEdit />
                  </a>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      deleteData(item._id)
                    }}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
