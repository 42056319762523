import { useState, useEffect } from "react"
import Carousel from "./carousel/Carousel"
import CardFluid from "./servicesFluidCard/FluidCard"
import Contact from "./contact/Contact"
import Title from "./shared/Title"
import Footer from "./footer/Footer"
import SwCarousel from "./services/SwCarousel"
import NavBar from "./header/NavBar"
import Preload from "./Preload"
import { useTranslation } from "react-i18next"
import "bootstrap/dist/js/bootstrap"

export default function Index() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    // Simulate a delay for the preload
    setTimeout(() => {
      setIsLoading(false)
    }, 5500)
  }, [])

  return (
    <>
      {isLoading ? (
        <Preload />
      ) : (
        <>
          <NavBar />
          <Carousel />
          <SwCarousel />
          <CardFluid />
          <Title title={t("navbar.contact")} id="contacto" />
          <Contact vertical={false} />
          <Footer />
        </>
      )}
    </>
  )
}
