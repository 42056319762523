import { PropsWithChildren } from "react"
import { createBrowserRouter, Navigate } from "react-router-dom"

import App from "../App"
import Index from "../components/index"
import Dispatch from "../components/servicesFluidCard/Dispatch"
import Tracking1 from "../components/servicesFluidCard/Tracking1"
import Tracking2 from "../components/servicesFluidCard/Tracking2"
import Blasting from "../components/servicesFluidCard/Blasting"
import Login from "../components/login/LoginForm"
import PublishedNews from "../components/news/NewsInPage"
import AboutUs from "../components/aboutus/AboutUs"
import Drawer from "../components/news/Drawer"

function Protected({ children }: PropsWithChildren) {
  const token = sessionStorage.getItem("token")

  if (token == null) return <Navigate to="/" />
  return <>{children}</>
}

export default createBrowserRouter([
  {
    path: "/:lng?",
    element: <App />,
    children: [
      {
        path: "",
        element: <Index />
      },
      {
        path: "nosotros",
        element: <AboutUs />
      },
      {
        path: "dispatch",
        element: <Dispatch />
      },
      {
        path: "tracking1",
        element: <Tracking1 />
      },
      {
        path: "tracking2",
        element: <Tracking2 />
      },
      {
        path: "voladura",
        element: <Blasting />
      },
      {
        path: "noticias",
        children: [
          {
            path: "",
            element: <PublishedNews />
          },
          {
            path: "admin",
            element: (
              <Protected>
                <Drawer />
              </Protected>
            )
          }
        ]
      },

      {
        path: "login",
        element: <Login />
      },
      {
        path: "*",
        element: <p>ERROR: 404</p>
      }
    ]
  }
])
