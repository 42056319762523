import "./Carousel.css"
import fotoCarousel1 from "../../assets/img/carousel1.webp"
import fotoCarousel3 from "../../assets/img/dtsmine3d2.webp"
import fotoTrackingGps from "../../assets/img/TrackingGps.webp"
import { useTranslation } from "react-i18next"

export default function () {
  const { t } = useTranslation()
  return (
    <div>
      <div id="carousel-hero" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carousel-hero"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carousel-hero"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carousel-hero"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner ">
          <div className="carousel-item active c-item">
            <img src={fotoCarousel1} className="d-block w-100 c-img" alt="" />
            <div
              className="carousel-caption"
              style={{
                background: "#00000090",
                boxShadow: "-6px -15px 30px 36px rgba(0,0,0,0.35) "
              }}
            >
              <p className="fs-2 text-uppercase">{t("carousel1.title")}</p>
              <h2 className="display-4 fw-bolder ">{t("carousel1.subtitle")}</h2>
            </div>
          </div>
          <div className="carousel-item c-item">
            <img src={fotoCarousel3} className="d-block w-100 c-img" alt="" />
            <div
              className="carousel-caption "
              style={{
                background: "#00000090",
                boxShadow: "-6px -15px 30px 36px rgba(0,0,0,0.35) "
              }}
            >
              <p className="fs-2 text-uppercase">{t("carousel2.title")}</p>
              <h2 className="display-4 fw-bolder ">{t("carousel2.subtitle")}</h2>
            </div>
          </div>
          <div className="carousel-item c-item">
            <img src={fotoTrackingGps} className="d-block w-100 c-img" alt="" />
            <div
              className="carousel-caption "
              style={{
                background: "#00000090",
                boxShadow: "-6px -15px 30px 36px rgba(0,0,0,0.35) "
              }}
            >
              <p className="fs-3 text-uppercase">{t("carousel3.title")}</p>
              <h2 className="display-4 fw-bolder ">{t("carousel3.subtitle")}</h2>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carousel-hero"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carousel-hero"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}
