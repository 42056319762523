import "./FluidCard.css"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import fotoCarousel3 from "../../assets/img/carousel1.webp"
import fotoGps from "../../assets/img/dtsmine3d2.webp"
import fotoGpsMesh from "../../assets/img/GpsMesh.webp"
import fotoVoladura from "../../assets/img/voladura.webp"

export default function FluidCardSection() {
  const { t } = useTranslation()
  const cardsData = [
    {
      title: t("services.card_1.title"),
      description: t("services.card_1.description_2"),
      image: fotoCarousel3,
      url: "/dispatch"
    },
    {
      title: t("services.card_2.title"),
      description: t("services.card_2.description_2"),
      image: fotoGps,
      url: "/tracking1"
    },
    {
      title: t("services.card_3.title"),
      description: t("services.card_3.description_2"),
      image: fotoGpsMesh,
      url: "/tracking2"
    },
    {
      title: t("services.card_4.title"),
      description: t("services.card_4.description_2"),
      image: fotoVoladura,
      url: "/voladura"
    }
  ]

  const cardRefs = cardsData.map(() =>
    useInView({ root: null, rootMargin: "100px", threshold: 0.5 })
  )

  return (
    <>
      {cardsData.map((card, index) => (
        <div className="card border-0" key={index}>
          <div className="container card-body card-img-overlay ">
            <div
              className={`row align-items-center text-white ${
                index % 2 === 1 ? "justify-content-end" : ""
              }`}
              style={{ zIndex: 1 }}
            >
              <div
                ref={cardRefs[index][0]}
                className={`bg__color ${
                  cardRefs[index][1] ? "slide-in" : ""
                } col-md-12 col-lg-5 col-xl-5`}
              >
                <h1 className="card-title">{card.title}</h1>
                <p>{card.description}</p>
                <a className="btn btn__color py-0 " href={card.url} target="_blank">
                  <span>{t("read_more")} &gt;&gt;</span>
                </a>
              </div>
            </div>
          </div>
          <div
            id="linear"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "linear-gradient(45deg, #000000, #ffffff00)"
            }}
          ></div>
          <img height="500" src={card.image} className="content__img--cover" alt="..." />
        </div>
      ))}
    </>
  )
}
