import { useEffect, useState } from "react"
import NavBar from "../header/NavBar"
import Contact from "../contact/Contact"
import Footer from "../footer/Footer"
import Title from "../shared/Title"
import { ENDPOINT_API } from "../../utils/const"
import { useTranslation } from "react-i18next"
import "./NewsInPage.css"
import axios from "../../utils/axios"
import about_news from "../../assets/img/about_analytcs.svg"

type Noticia = {
  _id: string
  title: string
  summary: string
  description: string
  published: boolean
  img?: string
}

export default function () {
  const { t } = useTranslation()
  const [news, setNews] = useState<Noticia[]>([])
  const publishedList = news.filter((element) => {
    return element.published
  })
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  })

  useEffect(() => {
    axios.get("/news").then((res) => {
      setNews(res.data)
    })
  }, [])
  return (
    <>
      <NavBar />
      <div className="header_background" />
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {publishedList.map((item, i) => (
            <button
              key={i}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={i}
              className={i === 0 ? "active" : ""}
              aria-current={i === 0 ? "true" : "false"}
              aria-label={`Slide ${i + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {publishedList.map((item, i) => (
            <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
              <img
                height="100%"
                src={`${ENDPOINT_API}/uploads/${item.img?.[0]}`}
                className="d-block w-100  content__img--cover"
                alt="..."
              />
              <div className="carousel-caption carousel-caption-center">
                <h1 className="card-title">{item.title}</h1>
                <p className="card-text ">{item.summary}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <main>
        <section className="services contenedor " id="servicio">
          <Title title={t("navbar.news")} />

          {publishedList.map((item, i) => (
            <div key={i} className="contenedor-servicio">
              <img src={`${ENDPOINT_API}/uploads/${item.img?.[1]}`} alt="" />
              <div className="contenido-parrafo">
                <h3 className="subtitulo-parrafo">{item.title}</h3>
                <p
                  className="parrafo-servivio"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></p>
              </div>
            </div>
          ))}

          <div className="contenedor-servicio">
            <div className="contenido-parrafo">
              <h3 className="subtitulo-parrafo">
                DISPATCH TRACKING SYSTEM CON GPS DE ALTA PRECISIÓN
              </h3>
              <p className="parrafo-servivio">
                La nueva implementación de la funcionalidad 'Tracking' en el software DTS (Dispatch and 
                Tracking System) está generando una gran emoción entre los usuarios, ya que se
                espera que mejore significativamente la eficiencia y la transparencia en la gestión
                de datos. Con esta nueva funcionalidad, los usuarios de DTS podrán rastrear los
                cambios y las actualizaciones de los datos en tiempo real, lo que permitirá una
                mejor toma de decisiones y una mayor precisión en la gestión de la información.
                Además, la implementación de esta funcionalidad mejorará la transparencia en la
                gestión de datos, lo que ayudará a prevenir errores y a mejorar la calidad de los
                datos.
              </p>
            </div>
            <img src={about_news} alt="" />
          </div>
        </section>
      </main>
      <Contact vertical={false} />
      <Footer />
    </>
  )
}
