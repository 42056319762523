import { useEffect, useState } from "react"
import ListNews from "./ListNews"
import Loader from "./Loader"
import Message from "./Message"
import axios from "../../utils/axios"
import Swal from "sweetalert2"
import FormNews from "./FormNews"
import Modal from "../../components/shared/Modal"

export interface Noticia {
  _id: string
  title: string
  summary: string
  description: string
  published: boolean
  img: string[]
}

export default function IndexNews() {
  const [statusModal, setStatusModal] = useState(false)
  const [news, setNews] = useState<Noticia[]>([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get("/news")
      .then((res) => {
        setNews(res.data)
        setError(null)
      })
      .catch(({ error }) => {
        setNews([])
        setError(error.response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [statusModal])

  async function deleteData(id: string) {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      return result.isConfirmed
    })

    if (!confirmed) {
      return
    }

    try {
      const response = await axios.delete(`/news/${id}`, {
        headers: {
          "x-access-token": sessionStorage.getItem("token")
        }
      })

      if (response) {
        const newsResponse = await axios.get("/news")
        setNews(newsResponse.data)
        await Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500
        })
      }
    } catch (error) {
      console.error(error)
      await Swal.fire("Error", "Ocurrió un error al eliminar la noticia", "error")
    }
  }
  const [id, setEditItemId] = useState("")
  return (
    <>
      <div className="">
        {loading && <Loader />}
        {error && <Message msg={`Error ${error}: ${error}`} />}
        {news && (
          <ListNews
            setEditItemId={setEditItemId}
            dataList={news}
            deleteData={deleteData}
            changeStatus={setStatusModal}
          />
        )}
        <Modal status={statusModal} changeStatus={setStatusModal}>
          <FormNews id={id} changeStatus={setStatusModal} />
        </Modal>
      </div>
    </>
  )
}
