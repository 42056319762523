import { FaPlus, FaSearch } from "react-icons/fa"
import "./ListUser.css"
import { useEffect, useState } from "react"
import axios from "../../utils/axios"
import Modal from "../../components/shared/Modal"
import CreateUser from "./CreateUser"
interface News {
  _id: string
  username: string
  email: string
  password: string
  roles: string[]
}

export default function () {
  const [statusModal, setStatusModal] = useState(false)
  const [users, setUsers] = useState<News[]>([])
  const [error, setError] = useState(null)
  console.log(error)
  useEffect(() => {
    axios
      .get("/users")
      .then((res) => {
        setUsers(res.data)
        setError(null)
      })
      .catch(({ error }) => {
        setUsers([])
        setError(error.response.data)
      })
  }, [statusModal])
  return (
    <div className="table-data">
      <div className="news">
        <div className="head">
          <h3>Usuarios</h3>
          <FaSearch className="bx" />
          <a className="btn btn-success btn-sm" onClick={() => setStatusModal(!statusModal)}>
            <FaPlus className="bx" />
            Nuevo
          </a>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Email</th>
              <th>Rol</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>
                  <span className="status completed">Admin</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal status={statusModal} changeStatus={setStatusModal}>
          <CreateUser changeStatus={setStatusModal} />
        </Modal>
      </div>
    </div>
  )
}
