import "./Preload.css"
import { useEffect } from "react"
import { preLoaderAnim } from "../animations"

export default () => {
  useEffect(() => {
    preLoaderAnim()
  }, [])
  return (
    <div className="preloader">
      <div className="texts-container">
        <span className="textname">Dispatch </span>
        <span className="textname">Tracking </span>
        <span className="textname">System</span>
      </div>
    </div>
  )
}
