import gsap from "gsap"

const tl = gsap.timeline()

export const preLoaderAnim = (): void => {
  tl.to("body", { duration: 0.1, ease: "power3.inOut" })
    .to(".landing", { duration: 0.05 })
    .to(".texts-container", { duration: 0, opacity: 1, ease: "Power3.easeOut" })
    .from(".texts-container span", {
      duration: 1,
      delay: 1,
      y: 70,
      skewY: 10,
      stagger: 0.4,
      ease: "Power3.easeOut"
    })
    .to(".texts-container span", {
      duration: 1,
      y: 70,
      skewY: -20,
      stagger: 0.2,
      ease: "Power3.easeOut"
    })
    .to(".landing", { duration: 0.05, css: { overflowY: "hidden", height: "unset" } })
    .to("body", { duration: 0.1, css: { overflowY: "scroll" }, ease: "power3.inOut" })
    .to(".preloader", {
      duration: 0.8,
      height: "0vh",
      ease: "Power3.easeOut",
      onComplete: mobileLanding,
      onCompleteParams: [],
      delay: -0.8
    })
    .from(".shapes .shape", {
      duration: 1,
      opacity: 0,
      delay: -0.8,
      ease: "power3.easeInOut",
      stagger: 0.8
    })
    .to(".preloader", {
      duration: 0,
      css: { display: "none" }
    })
}

export const mobileLanding = (): void => {
  if (window.innerWidth < 763) {
    tl.from(".landing__main2", {
      duration: 1,
      delay: 0,
      opacity: 0,
      y: 80,
      ease: "expo.easeOut"
    })
  }
}
