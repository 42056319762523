import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationEnglish from "./locales/en/translation.json"
import translationSpanish from "./locales/es/translation.json"
import HttpApi from "i18next-http-backend"

const resources = {
  es: { translation: translationSpanish },
  en: { translation: translationEnglish }
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources,
    supportedLngs: ["en", "es"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ["path", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"]
    },
    backend: {
      loadPaht: "/locales/{{lng}}/translation.json"
    }
  })

export default i18next
