import { useTranslation } from "react-i18next"
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin, FaInstagramSquare } from "react-icons/fa"
import logoGat from "../../assets/img/logoGAT.jpg"
import "./Footer.css"
export default function () {
  const { t } = useTranslation()
  return (
    <footer>
      <ul className="social_icon">
        <li>
          <a href="https://www.facebook.com/grupoalvicom" target="_blank">
            <FaFacebookSquare />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/GrupoAlvicom" target="_blank">
            <FaTwitterSquare />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/grupoalvicomtradingsrl/" target="_blank">
            <FaLinkedin />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/grupoalvicomtradingsrl/" target="_blank">
            <FaInstagramSquare />
          </a>
        </li>
      </ul>
      <ul className="menu">
        <li>
          <a href="/aboutus">{t("navbar.about")}</a>
        </li>
        <li>
          <a href="../#servicios">{t("navbar.services")}</a>
        </li>
        <li>
          <a href="../#contacto">{t("navbar.contact")}</a>
        </li>
      </ul>
      <p>
        <a className="copyright" href="https://www.alvicom.pe" target="_blank">
          Copyright © GRUPO ALVICOM TRADING 2023
        </a>
        <img src={logoGat} alt="" width={80} style={{ borderRadius: "10px" }} />
      </p>
    </footer>
  )
}
