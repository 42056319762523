import { ChangeEvent, FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"

import "./LoginForm.css"
import axios from "../../utils/axios"
import { AxiosError } from "axios"

export default function () {
  const [values, setValues] = useState({ email: "", password: "" })
  const navigate = useNavigate()

  const [axiosResponse, setAxiosResponse] = useState({ error: false, message: "" })

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    try {
      const { data } = await axios.post("/auth/signin", values)

      sessionStorage.setItem("token", data.token)
      setAxiosResponse(data)
      if (data) navigate("/noticias/admin", { replace: true })
    } catch (err) {
      const error = err as AxiosError<{ message: string }>
      setAxiosResponse({ error: true, message: error.response?.data.message ?? "" })
      console.log(error)
    }
  }

  return (
    <div className="container_login">
      <div className="forms">
        <div className="form login">
          <span className="title">DTSmine Login</span>
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="input-field">
                <input
                  type="text"
                  placeholder="Ingresa tu correo"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="input-field">
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                />
              </div>
              <div className="checkbox-text">
                <div className="checkbox-content">
                  <input type="checkbox" id="logCheck" />
                  <label htmlFor="logChek" className="text">
                    Remember me
                  </label>
                </div>
                <a href="#" className="text">
                  Forget Password
                </a>
              </div>
            </div>
            <div className="input-field button-login">
              <input onClick={handleSubmit} type="button" value="Login Now" />
            </div>
            {axiosResponse.error === true && (
              <div className="alert alert-danger" role="alert">
                <span>{axiosResponse.message}</span>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
