import { useState } from "react"
import { IconContext } from "react-icons"
import { FaGlasses, FaBars, FaTimes, FaHome, FaUserAlt } from "react-icons/fa"
import {
  Container,
  LogoContainer,
  Wrapper,
  Menu,
  MenuItem,
  MobileIcon,
  MenuItemHashLink,
  NavSignIn,
  NavBtnLink
} from "./NavBarStyle"
import { useTranslation } from "react-i18next"
import logo from "../../assets/img/Logo.webp"
import { HashLink } from "react-router-hash-link"
import LanguageSelect from "../LanguageSelect"

export default function Navbar() {
  const { t } = useTranslation()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showBackground, setShowBackground] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 70) {
      setShowBackground(true)
    } else {
      setShowBackground(false)
    }
  }
  window.addEventListener("scroll", changeBackground)

  return (
    <Container open={showBackground}>
      <Wrapper>
        <IconContext.Provider value={{ style: { fontSize: "1.5em" } }}>
          <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {showMobileMenu ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <LogoContainer>
            <HashLink to="/#">
              {" "}
              <img src={logo} alt="" />
              DTSmine
            </HashLink>
          </LogoContainer>

          <Menu open={showMobileMenu}>
            <MenuItem>
              <MenuItemHashLink to="/nosotros" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <FaHome />
                  {t("navbar.about")}
                </div>
              </MenuItemHashLink>
            </MenuItem>
            <MenuItem>
              <MenuItemHashLink to="/noticias" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <div>
                  <FaHome />
                  {t("navbar.news")}
                </div>
              </MenuItemHashLink>
            </MenuItem>
            <MenuItem>
              <MenuItemHashLink
                to="../#servicios"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <div>
                  <FaUserAlt />
                  {t("navbar.services")}
                </div>
              </MenuItemHashLink>
            </MenuItem>
            <MenuItem>
              <MenuItemHashLink
                to="../#contacto"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <div>
                  <FaGlasses />
                  {t("navbar.contact")}
                </div>
              </MenuItemHashLink>
            </MenuItem>
          </Menu>
          <NavSignIn>
            <NavBtnLink to="/login" target="_blank">
              Login
            </NavBtnLink>
            <LanguageSelect />
          </NavSignIn>
        </IconContext.Provider>
      </Wrapper>
    </Container>
  )
}
