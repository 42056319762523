import { Editor } from "@tinymce/tinymce-react"
import "react-image-crop/dist/ReactCrop.css"
import { useNews } from "./useNews"
import { FaCloudUploadAlt } from "react-icons/fa"
import "./StylesCrud.css"
import { ENDPOINT_API } from "../../utils/const"
import ReactCrop, { Crop } from "react-image-crop"
import React, { useRef, useState } from "react"
interface Props {
  id: string | null
  changeStatus: (value: boolean) => void
}
export default function ({ id, changeStatus }: Props) {
  const {
    images,
    stringImg,
    cropImageNow,
    setCompletedCrop,
    visibleCrop,
    form,
    handleChange,
    handleChecked,
    handleDescription,
    onDragLeave,
    onFileDrop,
    onDragEnter,
    handleRemoveImage,
    handleSubmit
  } = useNews({ id, changeStatus })

  const imgRef = useRef<HTMLImageElement>(null)
  const [cropImgFotocheck, setCropImgFotochek] = useState<Crop>()

  return (
    <>
      <div className="">
        <label htmlFor="title" className="mx-2 fw-bold fs-5">
          {id ? "EDITAR NOTICIA" : "CREAR NOTICIA"}
        </label>
        <form onSubmit={handleSubmit} className=" container content--form--news">
          <div className="row">
            <section className="col-xl-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="title ">TÍTULO</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Ingresa título"
                  value={form.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="summary">RESUMEN</label>
                <input
                  type="textarea"
                  className="form-control"
                  id="summary"
                  placeholder="Ingresa resumen"
                  value={form.summary}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input mt-2"
                  id="published"
                  onChange={handleChecked}
                  checked={form.published}
                />
                <label className="form-check-label  " htmlFor="published">
                  PUBLICADO
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="description">DESCRIPCIÓN</label>
                <Editor
                  apiKey="uchnrq8kx3ib29az7rmvxj952wjttj25b2fjtvt7wsihmen0"
                  value={form.description}
                  init={{
                    height: 350,
                    menubar: true,
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
                  }}
                  onEditorChange={handleDescription}
                />
              </div>
            </section>
            <section className="col-xl-6 col-sm-12 ">
              <div className="d-flex flex-column align-items-center">
                <label htmlFor="">Selecciona 2 imágenes</label>
                <div className="row d-flex flex-column align-items-center">
                  <div
                    className="drop-file-input"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDragLeave}
                  >
                    <div>
                      <FaCloudUploadAlt />
                    </div>
                    <input type="file" accept="image/*" onChange={onFileDrop} />
                  </div>
                  <div className="row text-white">
                    {stringImg && visibleCrop ? (
                      <div className="d-flex flex-column justify-content-center mb-3 ">
                        <button
                          type="button"
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            border: "none",
                            padding: "5px",
                            borderRadius: "5px",
                            margin: "5px 0"
                          }}
                          onClick={() => cropImageNow(imgRef.current)}
                        >
                          Done
                        </button>
                        <ReactCrop
                          ruleOfThirds
                          crop={cropImgFotocheck}
                          aspect={3 / 1.5}
                          onComplete={(c) => setCompletedCrop(c)}
                          onChange={setCropImgFotochek}
                        >
                          <img ref={imgRef} src={stringImg} alt="Crop me" />
                        </ReactCrop>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="row d-flex">
                    {images.map(({ file, url }, index) => (
                      <div key={index} className=" col-6 drop-file-preview__item px-0">
                        <img
                          src={url.includes("data:image") ? url : `${ENDPOINT_API}/uploads/${url}`}
                          alt=""
                        />
                        <div className="drop-file-preview__item__info">
                          <p>{file?.name}</p>
                        </div>
                        <span
                          className="drop-file-preview__item__del"
                          onClick={() => handleRemoveImage(index)}
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            Guardar
          </button>
        </form>
      </div>
    </>
  )
}
