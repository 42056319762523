import styled from "styled-components"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

type NavbarProps = {
  open?: boolean
}

export const Container = styled.div<NavbarProps>`
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 70px;
  background-color: ${({ open }) => (open ? "#000" : "transparent")};
  position: fixed;
  z-index: 1000;
  transition: all 1.5s ease;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  @media screen and (max-width: 960px) {
    padding: 0 0.5rem;
    transition: all 0.5s ease-in-out;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.5rem;
  }
  a {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
    text-decoration: none;
  }
  svg {
    fill: #e07924;
    margin-right: 0.5rem;
  }
  @media screen and (max-width: 800px) {
    a {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 500px) {
    a {
      font-size: 0;
    }
  }
`

export const Menu = styled.ul<NavbarProps>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  @media screen and (max-width: 960px) {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 70px;
    left: ${({ open }) => (open ? "0" : "-100%")}; //Import
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s all ease-in-out;
  }
`

export const MenuItem = styled.li`
  height: 100%;
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem 1.1rem;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 800;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    transition: all 0.9s ease-in-out;
    div {
      svg {
        fill: #f2f2f2;
      }
    }
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: none;
      fill: #fff;
      margin-right: 0.5rem;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    justify-content: center;
    div {
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 880px) {
    div {
      width: 40%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 500px) {
    div {
      width: 100%;
    }
  }
`
export const MenuItemHashLink = styled(HashLink)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem 1.1rem;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 800;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    transition: all 0.9s ease-in-out;
    div {
      svg {
        fill: #f2f2f2;
      }
    }
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: none;
      fill: #fff;
      margin-right: 0.5rem;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    justify-content: center;
    div {
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 880px) {
    div {
      width: 40%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 500px) {
    div {
      width: 100%;
    }
  }
`

export const MobileIcon = styled.div`
  display: none;
  justify-content: flex-end;
  @media screen and (max-width: 960px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: #fff;
      margin-right: 0.5rem;
    }
  }
`

export const NavSignIn = styled.nav`
  display: flex;
  align-items: center;
`

export const NavBtnLink = styled(Link)`
  border: 3px solid #fff;
  border-radius: 50px;
  white-space: nowrap;
  padding: 0.2rem 0.9rem;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  transition: all 1s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transition: all 0.5s ease-in-out;
    background: #fff;
    color: #010606;
  }
`
