import { ChangeEvent, FormEvent, useState } from "react"
import axios from "../../utils/axios"
import Swal from "sweetalert2"

interface Pros {
  changeStatus: (value: boolean) => void
}

export default function ({ changeStatus }: Pros) {
  const [users, setUsers] = useState({
    username: "",
    email: "",
    password: "",
    image: "",
    roles: [] as string[]
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      const { value, checked } = e.target
      let updatedRoles: string[] = [...users.roles]

      if (checked) {
        updatedRoles.push(value)
      } else {
        updatedRoles = updatedRoles.filter((role) => role !== value)
      }

      setUsers({
        ...users,
        roles: updatedRoles
      })
    } else {
      setUsers({
        ...users,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    setUsers({
      ...users,
      image: e.target.files?.[0]?.name ?? ""
    })
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const response = await axios.post("/auth/signup", users)
      changeStatus(false)
      await Swal.fire({
        icon: "success",
        title: "¡Enviado!",
        showConfirmButton: false,
        timer: 2000
      })
      console.log(response)
    } catch (error) {
      await Swal.fire({
        icon: "success",
        title: "¡Enviado!",
        showConfirmButton: false,
        timer: 2000
      })
      console.log("No se pudo guardar el usuario", error)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="container ">
      <div className="form-group px-2">
        <label htmlFor="username" className="fw-bold ">
          Nombre
        </label>
        <input
          type="text"
          className="form-control"
          id="username"
          name="username"
          placeholder="Ingresa nombre"
          value={users.username}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group px-2">
        <label htmlFor="email" className="fw-bold mt-3">
          Correo
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="Ingresa correo"
          value={users.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group px-2">
        <label htmlFor="password" className="fw-bold mt-3">
          Contraseña
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          placeholder="Ingresa la contraseña"
          value={users.password}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group px-2">
        <label htmlFor="image" className="fw-bold mt-3">
          Imagen
        </label>
        <input
          type="file"
          className="form-control"
          id="image"
          name="image"
          onChange={handleImage}
          required
        />
      </div>
      <div className="form-group px-2">
        <label htmlFor="roles" className="fw-bold mt-3">
          Rol
        </label>{" "}
        <br />
        <input
          type="checkbox"
          id="admin"
          name="roles"
          value="admin"
          checked={users.roles.includes("admin")}
          onChange={handleChange}
        />
        <label htmlFor="admin">Admin</label>
        <input
          className="ms-3"
          type="checkbox"
          id="user"
          name="roles"
          value="user"
          checked={users.roles.includes("user")}
          onChange={handleChange}
        />
        <label htmlFor="user">User</label>
      </div>
      <button
        type="submit"
        className="btn btn-primary m-2 d-flex justify-content-center align-items-center "
      >
        Guardar
      </button>
    </form>
  )
}
