import React from "react"
interface MessageProps {
  msg: string
}

export default function Message({ msg }: MessageProps) {
  return (
    <div>
      {/* <p>{msg}</p> */}
      <p dangerouslySetInnerHTML={{ __html: msg }} />
    </div>
  )
}
