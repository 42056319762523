import { FaTimes } from "react-icons/fa"
import "./Modal.css"
import React, { ReactNode } from "react"

interface ModalProps {
  children: ReactNode
  status: boolean
  changeStatus: (value: boolean) => void
}

export default function ({ children, status, changeStatus }: ModalProps) {
  return (
    <>
      {status && (
        <div className="overlay">
          <div className="content-modal">
            <div className="header-title"></div>
            <button className="closed" onClick={() => changeStatus(false)}>
              <FaTimes className="icon" />
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  )
}
